import {IonicColor} from '../../ui/ionic.definitions';
import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {Patient, PatientInterface} from '../users/users.models';
import {Workflow} from '../workflows/workflows.models';


// export const PATIENT_STEP_STATUSES = ['Non attribué', 'Prescrit', 'En cours', 'Terminé'] as const;
// export type PatientStepStatus = (typeof PATIENT_STEP_STATUSES)[number];
export type PatientStepStatus = string; // Can't really list them anymore

export const STEP_IDS = ['biology'] as const;
export type StepId = (typeof STEP_IDS)[number];

export type PatientStepHistoryEntry = {
  date: Date;
  status: string;
};

export interface PatientStepInterface extends FollowMeEntityInterface {
  status: PatientStepStatus;
  step: StepId;
  history: PatientStepHistoryEntry[];
  patientIri: string;
  patient?: PatientInterface;
  workflow?: Workflow;
  '@type': 'PatientStep';
}

type CourseStepColor = 'success' | 'warning' | 'danger' | 'medium' | 'secondary-contrast';

export type CourseStepCommon = {
  reference: string,
  text: string,
  title: string,
  chipText?: string,
  color: CourseStepColor,
  time: Date,
  isOpen: boolean,
};

export type CourseStepPatientStep = {
  type: 'patient-step',
  step: StepId,
  status: PatientStepStatus,
  stepStatus: PatientStepStatus,
} & CourseStepCommon;
export type CourseStepMaxMessage = {
  type: 'max-message',
} & CourseStepCommon;
export type CourseStepInfoMessage = {
  type: 'info-message',
} & CourseStepCommon;

export type CourseStep = CourseStepPatientStep | CourseStepMaxMessage | CourseStepInfoMessage;

// export interface CourseStepActionInterface {
//   callback: () => void,
//   text: string,
//   class: string,
// }

export class PatientStep extends AbstractFollowMeEntity implements PatientStepInterface {
  public override '@type' = 'PatientStep' as const;

  public updatedAt: Date = null;
  public status: PatientStepStatus = null;
  // public stepStatus: PatientStepStatus = null;
  public step: StepId = null;
  public history: PatientStepHistoryEntry[] = null;
  public patientIri: string = null;
  public patient?: Patient = null;
  public workflow?: Workflow = null;

  public static override getEntityName(): 'patient_step' {
    return 'patient_step';
  }

  public get color(): IonicColor {
    return this.getColorForStatus(this.status);
  }

  public getColorForStatus(status: string): IonicColor {
    return this.workflow?.metadata?.place?.[status]?.color ?? 'secondary';
  }

  public override fromJson(jsonData: Record<string, unknown>): this {
    super.fromJson(jsonData);

    this.history = this.history.map(historyEntry => ({
      ...historyEntry,
      date: typeof historyEntry.date === 'string' ? new Date(historyEntry.date) : historyEntry.date,
    }));

    return this;
  }
}
