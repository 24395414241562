import {createReducer, on} from '@ngrx/store';
import {
  mercureActionConnectSuccess,
  mercureActionDisconnect,
  mercureActionSetJwt,
} from './actions';


export const MERCURE_FEATURE_KEY = 'mercure';

export type State = {
  jwt?: string,
  mercureUrl?: string,
  // init: still waiting for jwt
  // ready: we have jwt and are waiting for the EventSource
  // connected: we are connected and the EventSource is ready
  status: 'init' | 'ready' | 'connected';
};

export interface MercurePartialState {
  readonly [MERCURE_FEATURE_KEY]: State;
}

export const initialState: State = {
  status: 'init',
};

const updateStatus = (state: State): State => {
  if (state.status === 'connected') {
    return state;
  }
  const status = state.jwt
    ? 'ready'
    : 'init'
  ;
  return {
    ...state,
    status,
  };
};

export const mercureReducer = createReducer(
  initialState,

  // set jwt
  on(mercureActionSetJwt, (state, {jwt}) => updateStatus({
    ...state,
    jwt,
  })),

  // connect success
  on(mercureActionConnectSuccess, (state) => ({
    ...state,
    status: 'connected',
  })),

  // Disconnect
  on(mercureActionDisconnect, (state) => ({
    ...state,
    status: 'init',
    jwt: null,
  })),
);
