import {AbstractEntity, EntityInterface, EntityWithInheritanceInterface, urlJoin} from '@forlabs/api-bridge';


export type Permission = 'update' | 'delete';
export interface FollowMeEntityInterface extends EntityWithInheritanceInterface {
  '@type': string;
  permissions: Permission[];
}

export class AbstractFollowMeEntity extends AbstractEntity implements FollowMeEntityInterface {
  public override id: string = null;
  public '@type': string = null;
  public permissions: Permission[] = null;

  public get uri(): string {
    return this['@id'];
  }

  public static override getBaseUri(): string {
    return urlJoin('/api/', super.getBaseUri());
  }
}

export const selectId = (entity: EntityInterface): string => entity['@id'];
