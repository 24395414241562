import {Component, inject} from '@angular/core';
import {FollowmeDynamicEnvironment, InitService} from '@followme/domain';
import {ENVIRONMENT} from '@forlabs/api-bridge';


@Component({
  selector: 'fm-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public environment = inject(ENVIRONMENT) as FollowmeDynamicEnvironment;

  constructor(
    private initService: InitService,
  ) {
    this.initService.init();
    // this.articleCollectionService.getByKey('biology').subscribe(x => console.log(x));
    // this.articleCollectionService.getMetadata().subscribe(x => console.log(x));
    // this.articleSelectorService.oneByIri$('biology').subscribe(x => console.log(x));
    // this.articleSelectorService.all$.subscribe(x => console.log(x));
  }
}
