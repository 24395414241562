import {createReducer, on} from '@ngrx/store';
import {getAll, loadChartsSuccess, loadChartsFailure, deleteChartFailure, deleteChartSuccess, deleteChart, updateChart, updateChartSuccess, updateChartFailure, createChart, createChartSuccess, createChartFailure, updateCharts} from './charts.actions';
import {ChartInterface} from './charts.models';


export interface ChartsState {
  charts: ChartInterface[];
  loading: boolean;
  error: any;
  areas_color: any[];
}

export const initialChartState: ChartsState = {
  charts: [],
  loading: false,
  error: null,
  areas_color: [],
};

export const chartsReducer = createReducer(
  initialChartState,

  on(getAll, (state, {loading}) => ({
    ...state,
    loading: loading,
    error: null,
  })),

  on(loadChartsSuccess, (state, {charts, areas_color}) => ({
    ...state,
    charts,
    loading: false,
    areas_color,
  })),

  on(loadChartsFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  
  
  on(createChart, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  
  on(createChartSuccess, (state, {chart}) => ({
    ...state,
    loading: false,
    charts: [...state.charts, chart],
  })),

  on(createChartFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  on(updateCharts, (state, {charts}) => ({
    ...state,
    charts,
    loading: false,
    error: null,
  })),

  on(updateChart, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  
  on(updateChartSuccess, (state, {id, chart}) => ({
    ...state,
    loading: false,
    charts: state.charts.map((existingChart) =>
      existingChart.id === id ? {...existingChart, ...chart} : existingChart,
    ),
  })),

  on(updateChartFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  on(deleteChart, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(deleteChartSuccess, (state, {id}) => ({
    ...state,
    charts: state.charts.filter(chart => chart.id !== id),
    loading: false,
  })),

  on(deleteChartFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
);
