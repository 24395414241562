import {CommonModule} from '@angular/common';
import {AfterContentInit, Directive, ElementRef, inject, Input, NgModule, OnChanges} from '@angular/core';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';
import {IonicModule} from '@ionic/angular';


@Directive({
  selector: '[fmSlicedText]',
  // FIXME: Once MatTooltip is available as a standalone directive, use the directive composition API:
  //  hostDirectives: [{
  //    directive: MatTooltip,
  //  }],
})
export class SlicedTextDirective extends MatTooltip implements AfterContentInit, OnChanges {
  @Input() public fmSlicedText: string;
  @Input() public length: number | null = null; // The slice length. If empty, use css instead. Works for single-line.
  @Input() public showTooltip: 'always' | 'if-sliced' | 'never' = 'if-sliced';
  public el: ElementRef = inject(ElementRef);

  public ngOnChanges(): void {
    this.update();
  }

  public ngAfterContentInit(): void {
    this.update();
  }

  private update(): void {
    // Update text
    this.el.nativeElement.innerText = (this.length !== null && this.fmSlicedText.length > this.length)
      ? this.fmSlicedText.slice(0, this.length) + '...'
      : this.fmSlicedText
    ;

    // Update class
    const CSS_SLICED_CLASS = 'css-sliced';
    if (this.length === null) {
      this.el.nativeElement.classList.add(CSS_SLICED_CLASS);
    } else {
      this.el.nativeElement.classList.remove(CSS_SLICED_CLASS);
    }

    // Update tooltip (async because we may need to wait for DOM if we're to detect CSS "text-overflow: ellipsis")
    // Long timeout so that animations are (hopefully) done when we trigger.
    // TODO: is there a nice way to have "text-overflow: ellipsis" off during animations and active only when the animation
    //  ends?
    setTimeout(() => this.updateTooltip(), 1000);
  }

  private updateTooltip(): void {
    let showTooltip = this.showTooltip === 'always';
    if (this.showTooltip === 'if-sliced') {
      // Detection of whether the text is sliced depends on whether we use CSS (length = null) or TS (length !== null) to slice.
      if (this.length === null) {
        // Detect if CSS "text-overflow: ellipsis" is active: https://stackoverflow.com/a/35157976
        const tolerance = 2;
        showTooltip = this.el.nativeElement.offsetWidth + tolerance < this.el.nativeElement.scrollWidth;
      } else {
        showTooltip = this.fmSlicedText.length > this.length;
      }
    }
    if (showTooltip) {
      this.message = this.fmSlicedText;
    } else {
      this.message = undefined;
    }
  }
}

@NgModule({
  imports: [CommonModule, IonicModule, MatTooltipModule],
  declarations: [SlicedTextDirective],
  exports: [SlicedTextDirective],
})
export class SlicedTextModule {
}
