import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';


type Op = {
  insert: string | {image: string};
};

export interface ArticleInterface extends FollowMeEntityInterface {
  title: string;
  content: {ops: Op[]};
}

export class Article extends AbstractFollowMeEntity implements ArticleInterface {
  public title: string = null;
  public content: {ops: Op[]} = null;

  public static override getEntityName(): 'article' {
    return 'article';
  }
}
