import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EntitySelectorService} from '../metadata';
import {WorkflowButton, WorkflowButtonInterface} from './workflow-buttons.models';
import {WorkflowButtonCollectionService} from './workflow-buttons.ngrx-data';


@Injectable()
export class WorkflowButtonSelectorService extends EntitySelectorService<WorkflowButtonInterface, WorkflowButton> {
  constructor(
    store: Store,
    workflowButtonCollectionService: WorkflowButtonCollectionService,
  ) {
    super(
      store,
      WorkflowButton,
      workflowButtonCollectionService,
    );
  }
}
