import {EntityAction} from '@ngrx/data/src/actions/entity-action';
import {HttpMethods} from '@ngrx/data/src/dataservices/interfaces';
import {createAction, props} from '@ngrx/store';
import {TypedAction} from '@ngrx/store/src/models';
import {UserInterface} from '../users/users.models';


export class ActionTypes {
  public static INIT = '[Meta] Init';
  public static SECURE_ACTION = '[Meta] Secure action';
  public static SECURE_ACTION_FAILURE = '[Meta] Secure action failure';
}

export const metaActionInit = createAction(ActionTypes.INIT, props<{ user: UserInterface }>());

export type SecureActionSpecs = {
  method: HttpMethods | 'PATCH',
  request_uri: string,
};
export type SecureAction<A extends EntityAction> = TypedAction<typeof ActionTypes.SECURE_ACTION> & {action: A};
// FIXME: Figure out how to properly type this?
// export const metaActionSecureAction: ActionCreator<typeof ActionTypes.SECURE_ACTION, SecureAction<EntityAction>> =
//   <A extends EntityAction>({action}: {action: A}): SecureAction<A> =>
//     createAction(ActionTypes.SECURE_ACTION, props<{ action: A }>())({action});

export const metaActionSecureAction =
  createAction(
    ActionTypes.SECURE_ACTION,
    props<{action: EntityAction, password: string, specs: SecureActionSpecs}>(),
  );

export const FailedSecureAction =
  createAction(
    ActionTypes.SECURE_ACTION_FAILURE,
    props<{action: EntityAction, error: Error, specs: SecureActionSpecs}>(),
  );
