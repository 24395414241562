import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EntitySelectorService} from '../metadata';
import {Workflow, WorkflowInterface} from './workflows.models';
import {WorkflowCollectionService} from './workflows.ngrx-data';


@Injectable()
export class WorkflowSelectorService extends EntitySelectorService<WorkflowInterface, Workflow> {
  constructor(
    store: Store,
    workflowCollectionService: WorkflowCollectionService,
  ) {
    super(
      store,
      Workflow,
      workflowCollectionService,
    );
  }
}
