import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EntitySelectorService} from '../metadata';
import {Info, InfoInterface} from './infos.models';
import {InfoCollectionService} from './infos.ngrx-data';


@Injectable()
export class InfoSelectorService extends EntitySelectorService<InfoInterface, Info> {
  constructor(
    store: Store,
    infoCollectionService: InfoCollectionService,
  ) {
    super(store, Info, infoCollectionService);
  }
}
