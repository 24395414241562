/**
 * This method is used to format a date to a string in the format 'YYYY-MM-DD' without considering the timezone.
 * @param date
 */
export function formatDateIgnoringTimezone(date: Date): string {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
