import {Injectable} from '@angular/core';
import {
  EntityActionDataServiceError,
  EntityCollection,
  EntityCollectionReducerMethodMap,
  EntityCollectionReducerMethods,
  EntityDefinition,
  EntityDefinitionService,
  ofEntityOp,
  PersistenceResultHandler,
} from '@ngrx/data';
import {EntityAction} from '@ngrx/data/src/actions/entity-action';
import {Actions, createEffect} from '@ngrx/effects';
import {catchError, delay, map, of, switchMap} from 'rxjs';
import {ArticleDataService} from './articles.ngrx-data';


export enum ForlabsEntityOp {
  QUERY_METADATA = '@ngrx/data/query-metadata',
  QUERY_METADATA_SUCCESS = '@ngrx/data/query-metadata/success',
  QUERY_METADATA_ERROR = '@ngrx/data/query-metadata/error',
}

export type EntityCollectionWithMetadata<T, M> = EntityCollection<T> & {metadata?: M};

export class ForlabsEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(
    public override entityName: string,
    public override definition: EntityDefinition<T>,
  ) {
    super(entityName, definition);

    this.methods[ForlabsEntityOp.QUERY_METADATA] = this.queryMetadata.bind(this);
    this.methods[ForlabsEntityOp.QUERY_METADATA_ERROR] = this.queryMetadataError.bind(this);
    this.methods[ForlabsEntityOp.QUERY_METADATA_SUCCESS] = this.queryMetadataSuccess.bind(this);
  }

  protected queryMetadata(collection: EntityCollection<T>): EntityCollection<T> {
    return this.setLoadingTrue(collection);
  }

  protected queryMetadataError(
    collection: EntityCollection<T>,
    _action: EntityAction<EntityActionDataServiceError>,
  ): EntityCollection<T> {
    return this.setLoadingFalse(collection);
  }

  protected queryMetadataSuccess<M>(
    collection: EntityCollectionWithMetadata<T, M>,
    action: EntityAction<M>,
  ): EntityCollectionWithMetadata<T, M> {
    const data = action.payload.data;
    return {
      ...collection,
      loaded: true,
      loading: false,
      metadata: data,
    };
  }
}

@Injectable()
export class ForlabsEntityCollectionReducerMethodsFactory {
  constructor(
    private entityDefinitionService: EntityDefinitionService,
  ) {
  }

  public create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new ForlabsEntityCollectionReducerMethods(entityName, definition);
    return methodsClass.methods;
  }
}

// TODO: define effect for QUERY_METADATA that triggers either QUERY_METADATA_S or QUERY_METADATA_E
@Injectable()
export class ForlabsEffects {
  private readonly responseDelay = 10;

  public downloadMetadata$ = createEffect(() => this.actions$.pipe(
    ofEntityOp(ForlabsEntityOp.QUERY_METADATA),
    switchMap((action: EntityAction) =>
      // TODO: Should not be articlesDataService, but the proper dataservice depending on the action, and it should
      //       implement an interface to make sure it has a getMetadata() method.
      this.articlesDataService.getMetadata().pipe(
        map(this.resultHandler.handleSuccess(action)), // FIXME: does this do what we want?
        catchError((error: Error) => of(this.resultHandler.handleError(action)(error)).pipe(
          delay(this.responseDelay),
        )),
      ),
    ),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly articlesDataService: ArticleDataService,
    private readonly resultHandler: PersistenceResultHandler,
  ) {
  }
}
