import {Article, ArticleInterface} from '../articles/articles.models';
import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {Info, InfoInterface} from '../infos/infos.models';
import {User, UserInterface} from '../users/users.models';


export interface OrganizationInterface extends FollowMeEntityInterface {
  name: string;
  users?: UserInterface[];
  usersIris: string[];
  articles?: ArticleInterface[];
  articlesIris: string[];
  infos?: InfoInterface[];
  infosIris: string[];
}

export class Organization extends AbstractFollowMeEntity implements OrganizationInterface {
  public name: string = null;
  public serviceName: string = null;
  public city: string = null;
  public zip: string = null;
  public phone: string = null;
  public users?: User[] = null;
  public usersIris: string[] = null;
  public articles?: Article[] = null;
  public articlesIris: string[] = null;
  public infos?: Info[] = null;
  public infosIris: string[] = null;

  public static override getEntityName(): 'organization' {
    return 'organization';
  }
}
