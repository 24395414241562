import {PlaceStatuses, WorkflowInterface} from './workflows.models';


export class WorkflowsHelpers {
  public static getImplicitSteps(workflow: WorkflowInterface | null, status: string): PlaceStatuses {
    return workflow?.metadata?.place?.[status]?.implicit_steps ?? {};
  }

  public static getImplicitStepsStatus(workflow: WorkflowInterface | null, status: string, implicitStep: string): string | null {
    return this.getImplicitSteps(workflow, status)[implicitStep]?.status ?? null;
  }
}
