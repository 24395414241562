import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {take, tap} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CurrentUserService} from './current-user.service';


export const NO_METADATA_INIT_ACTION_REGEXPS = new InjectionToken<RegExp[]>('NO_METADATA_INIT_ACTION_REGEXPS');

@Injectable({
  providedIn: 'root',
})
export class InitService {
  constructor(
    @Inject(NO_METADATA_INIT_ACTION_REGEXPS) @Optional() private noMetadataInitAction: RegExp[],
    private router: Router,
    private currentUserService: CurrentUserService,
  ) {
  }

  public init(): void {
    this.router.events.pipe(
      // Listen to all NavigationStart events
      filter((event => event instanceof NavigationStart) as ((event) => event is NavigationStart)),
      // Once we NavigationStart a route that does not tell us "don't init", we can init
      filter(event => !(this.noMetadataInitAction ?? []).some(regex => regex.test(event.url))),
      take(1),
      tap(() => this.currentUserService.init()),
    ).subscribe();
  }
}
