import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ChartsState} from './charts.reducer';


export const selectChartState = createFeatureSelector<ChartsState>('charts');

export const selectCharts = createSelector(
  selectChartState,
  (state: ChartsState) => state.charts,
);

export const selectChartById = (id: string) => createSelector(
  selectChartState,
  (state: ChartsState) => {
    let chartSelected = null;
    state.charts.forEach((chart) => {
      if (chart.id.toString() === id) {
        chartSelected = chart;
      }
    });
    return chartSelected;
  },
);

export const selectLoading = createSelector(
  selectChartState,
  (state: ChartsState) => {
    return state.loading;
  },
);

export const selectError = createSelector(
  selectChartState,
  (state: ChartsState) => state.error,
);