import {Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';


export type TrackerEventType = 'termsAccepted' | 'termsOpened' | 'helpInfoAccessed' | 'contactLinksShared';
@Injectable({
  providedIn: 'root',
})
export class UserMetricService {
  constructor(private apiClient: ApiClient) {
  }
  public track(event: TrackerEventType, accountCreationToken: string | null = null) : void {
    this.apiClient.post<{event: TrackerEventType, accountCreationToken: string | null}>(
      '/api/user_metric',
      {event , accountCreationToken},
    ).subscribe();
  }
}
