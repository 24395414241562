import {APP_INITIALIZER, ErrorHandler, isDevMode} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import {captureConsoleIntegration, httpClientIntegration} from "@sentry/integrations";


// FIXME: this could be in an independent library that would come with sentry SDK as a dependency
// Also, the configuration should be dynamic, and not hardcoded in the code
export function initSentry(sentryDsn: string): void
{
  if (isDevMode()) {
    console.log('Sentry is disabled in dev mode')
    return;
  }

  // https://forlabs.sentry.io/onboarding/setup-docs/?product=performance-monitoring&product=session-replay
  // FIXME: retrieve dynamic environment values to configure Sentry (needs changes in the API bridge)
  // Sentry.init({
  //   dsn: sentryDsn,
  //   integrations: [
  //     captureConsoleIntegration({levels: ['error', 'assert']}),
  //     httpClientIntegration({
  //       failedRequestStatusCodes: [400, 404, 500],
  //       // TODO: when we have a dynamic environment, we can use it to set the targets
  //       // failedRequestTargets: ['https://api.followme.forlabs.fr'],
  //     }),
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   // This option is required for capturing headers and cookies
  //   sendDefaultPii: true,
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   // TODO: when we have a dynamic environment, we can use it to set the targets
  //   // tracePropagationTargets: [
  //   //   'localhost',
  //   //   /^https:\/\/dev\.followme\.forlabs\.fr/,
  //   //   /^https:\/\/followme\.midgar-vpn\.forlabs\.fr/,
  //   // ],
  //   /////////////////////////////////////////////////////////
  //   // Session Replay
  //   /////////////////////////////////////////////////////////
  //   // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysSessionSampleRate: 0,
  //   // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   replaysOnErrorSampleRate: 1.0,
  // });
}

export function getSentryAngularProviders()
{
  // Disable for local dev
  if (isDevMode()) { return [] }

  return [
    // Fixme: showDialog could optionnaly come from the dynamic environment
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({showDialog: false})
    },
    {
      provide:
      Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ]
}
