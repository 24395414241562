import {NgModule} from '@angular/core';
import {AvatarModule} from './avatar/avatar.component';
import {SlicedTextModule} from './sliced-text/sliced-text.component';


@NgModule({
  imports: [AvatarModule, SlicedTextModule],
  exports: [AvatarModule, SlicedTextModule],
})
export class UiCoreModule {}
