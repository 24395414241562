import {Component, Input, NgModule, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {User} from '@followme/domain';
import {IonicModule} from '@ionic/angular';


@Component({
  selector: 'fm-avatar',
  template: `
    <div style="border-radius: 80px; background-color: white; width: fit-content; height: fit-content">
      <div class="avatar" [style.width]="dimension + 'px'"  [style.height]="dimension + 'px'">
        <ion-text>{{getInitials()}}</ion-text>
      </div>
    </div>
  `,
  styles: [`
    .avatar {
      background-color: rgba(var(--ion-color-primary-rgb), 0.1);
      border-radius: 100%;
      color: var(--ion-color-primary);
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar > ion-text {
      font-size: 18px;
      font-weight: 600;
    }
  `],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class AvatarComponent {
  @Input() public user: User;
  @Input() public dimension = 24;

  public getInitials(): string {
    return this.user.lastName[0].toUpperCase() + this.user.firstName[0].toUpperCase();
  }
}

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class AvatarModule {
}
