import {Inject, Optional} from '@angular/core';
import {DateFnsAdapter} from '@angular/material-date-fns-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {parse} from 'date-fns';


export class DatepickerDateAdapter extends DateFnsAdapter {
  constructor(
  // eslint-disable-next-line @typescript-eslint/indent
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
  ) {
    super(dateLocale);
  }

  public override getFirstDayOfWeek(): number {
    return 1; // Monday
  }

  public override parse(value: string | number | Date, parseFormat: string | string[]): Date | null {
    if (typeof value == 'string' && value.length > 0) {
      value = value.replace(/[^\d]+/g, '/');

      const formats = Array.isArray(parseFormat) ? parseFormat : [parseFormat];

      if (!parseFormat.length) {
        throw Error('Formats array must not be empty.');
      }

      for (const currentFormat of formats) {
        const fromFormat = parse(value, currentFormat, new Date(), {locale: this.locale});

        if (this.isValid(fromFormat)) {
          return fromFormat;
        }
      }

      return this.invalid();
    } else if (typeof value === 'number') {
      return new Date(value);
    } else if (value instanceof Date) {
      return this.clone(value);
    }

    return null;
  }
}
