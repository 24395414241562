import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {TransitionParameter} from '../workflows/workflows.models';


export interface WorkflowButtonInterface extends FollowMeEntityInterface {
  name: string;
  workflowName: string;
  transitionName: string;
  subjectType: 'App\\Entity\\Patient' | 'App\\Entity\\PatientStep';
  parameters: TransitionParameter[];
}

export class WorkflowButton extends AbstractFollowMeEntity implements WorkflowButtonInterface {
  public override '@type' = 'WorkflowButton' as const;

  public name: string = null;
  public workflowName: string = null;
  public transitionName: string = null;
  public subjectType: 'App\\Entity\\Patient' | 'App\\Entity\\PatientStep' = null;
  public parameters: TransitionParameter[] = null;

  public static override getEntityName(): 'workflow_button' {
    return 'workflow_button';
  }
}
