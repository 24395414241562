import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';
import {DefaultDataServiceConfig, EntityCollectionServiceElementsFactory, HttpUrlGenerator} from '@ngrx/data';
import {Action, ScannedActionsSubject} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EntityCollectionService, FollowMeDataService} from '../metadata';
import {Info, InfoInterface} from './infos.models';


@Injectable()
export class InfoCollectionService extends EntityCollectionService<InfoInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
  ) {
    super(Info, serviceElementsFactory, scannedActions$);
  }
}

@Injectable()
export class InfoDataService extends FollowMeDataService<InfoInterface> {
  constructor(
    apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(Info, apiClient, http, httpUrlGenerator, config);
  }
}
