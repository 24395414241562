import {Injectable, Type} from '@angular/core';
import {UrlTree} from '@angular/router';
import {AuthGuardsService} from '@forlabs/api-bridge';
import {Observable, OperatorFunction, take} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Organization} from '../+state/organizations/organizations.models';
import {HealthPro, User} from '../+state/users/users.models';


export const assertInstanceOf: <T, U extends T>(constructor: Type<U>) => OperatorFunction<T, U> =
  <T, U extends T>(constructor: Type<U>) => map(item => {
    if (item instanceof constructor) {
      return item;
    }
    console.error({item, type: constructor});
    throw new Error('Type assertion failed in assertInstanceOf operator');
  });
export const onlyInstanceOf: <T, U extends T>(constructor: Type<U>) => OperatorFunction<T, U> =
  <T, U extends T>(constructor: Type<U>) => filter((item: T): item is U => (item instanceof constructor));


@Injectable({
  providedIn: 'root',
})
export class FollowMeAuthGuardsService extends AuthGuardsService {
  public guardUserOrganization(organizationId: string): Observable<boolean | UrlTree> {
    return this.authService.currentUser$.pipe(
      filter((user: User | null | undefined) => user !== undefined),
      take(1),
      map((currentUser) => {
        // User is not connected: redirect to homepage
        if (currentUser === null) {
          console.log('Guard user organization: Redirecting to home page', currentUser);
          return this.router.createUrlTree(['/']);
        }
        if (currentUser.role === 'ROLE_ADMIN'
          || (currentUser.organizationIri ?? currentUser.organization['@id']) === Organization.getUriById(organizationId)) {
          return true;
        } else {
          // On first load, redirect to home page, otherwise block navigation
          if (this.router.getCurrentNavigation().id === 1) {
            console.log('Guard user organization on first load with wrong orga: Redirecting to home page', currentUser, organizationId);
            return this.router.createUrlTree(['/']);
          }
          console.log('Guard user organization with wrong orga: Preventing page load', currentUser, organizationId);
          return false;
        }
      }),
    );
  }

  public guardHealthProMustChangePassword(
    mode: 'on-regular-pages' | 'on-password-page' = 'on-regular-pages',
  ): Observable<boolean | UrlTree> {
    return this.authService.currentUser$.pipe(
      filter((user: User | null | undefined) => user !== undefined),
      take(1),
      map((currentUser) => {
        // This only applies to health pros. Block if user is not logged in or not a health pro.
        if (!(currentUser instanceof HealthPro)) {
          console.log('Guard HealthPro Must Change Password: Preventing page load', currentUser);
          return false;
        }

        // User does not need a password change.
        if (!currentUser.passwordDefault) {
          if (mode === 'on-password-page') {
            console.log('Guard HealthPro Must Change Password: Preventing page load', currentUser);
          }
          return mode === 'on-regular-pages';
        }

        // User must change their password.
        if (mode === 'on-regular-pages') {
          console.log('Guard HealthPro Must Change Password: Redirecting to password update page', currentUser);
          return this.router.createUrlTree(['/password/forced-update']);
        } else {
          return true;
        }
      }),
    );
  }
}
