import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {PatientStepInterface, PatientStepStatus} from '../patient-steps/patient-steps.models';
import {Patient, PatientInterface} from '../users/users.models';


export interface CourseMessageInterface extends FollowMeEntityInterface {
  workflowName: string;
  transitionName: string;
  patientIri: string;
  patient?: PatientInterface;
  date: Date;
  isDateDisplayed: boolean;
  messageId: string;
  parameters: Record<string, string>;
  patientStepIri: string;
  patientStep?: PatientStepInterface;
  stepStatus?: PatientStepStatus;
  color?: string;
  implicitStep?: string;
  icon?: string;
  '@type': 'CourseMessage';
}

export type CourseStepColor = 'success' | 'warning' | 'danger' | 'medium' | 'secondary-contrast';

export class CourseMessage extends AbstractFollowMeEntity implements CourseMessageInterface {
  public override '@type' = 'CourseMessage' as const;

  public workflowName: string = null;
  public transitionName: string = null;
  public patientIri: string = null;
  public patient?: Patient = null;
  public date: Date = null;
  public isDateDisplayed: boolean = null;
  public messageId: string = null;
  public parameters: Record<string, string> = null;
  public patientStepIri: string = null;
  public patientStep?: PatientStepInterface = null;
  public stepStatus?: PatientStepStatus = null;
  public color?: CourseStepColor = null;
  public implicitStep?: string = null;
  public icon?: string = null;

  public static override getEntityName(): 'course_message' {
    return 'course_message';
  }

  public override fromJson(jsonData: Record<string, unknown>): this {
    super.fromJson(jsonData);
    if (typeof this.date === 'string') {
      this.date = new Date(this.date);
    }
    return this;
  }
}

export type MessageCommon = {
  reference: string,
  text: string,
  title: string,
  chipText?: string,
  color: CourseStepColor,
  date: Date,
  isOpen: boolean,
};

export type StepMessage = {
  type: 'step-message',
  courseMessage: CourseMessageInterface,
  isDateDisplayed: boolean,
  step: string,
  status: string,
  articleId?: string,
} & MessageCommon;
export type MaxMessage = {
  type: 'max-message',
} & MessageCommon;
export type InfoMessage = {
  type: 'info-message',
} & MessageCommon;

export type Message = StepMessage | MaxMessage | InfoMessage;
