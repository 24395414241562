import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {Contact, FollowMeAuthGuardsService, Patient} from '@followme/domain';
import {CguPage} from "./_pages/cgu/cgu.page";


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./_pages/main-nav/main-nav.module').then((m) => m.MainNavModulePageModule),
    canActivate: [
      (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(FollowMeAuthGuardsService).guardUserType(
        state,
        [Patient, Contact],
      ),
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./_pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [() => inject(FollowMeAuthGuardsService).guardNoAuth()],
  },
  // Redirections for short-links
  {path: 'p/:token', redirectTo: '/first-connexion/:token/verify-identity-form/patient'},
  {path: 'c/:token', redirectTo: '/first-connexion/:token/verify-identity-form/contact'},
  {
    path: 'cgu',
    loadChildren: () =>
      import('./_pages/cgu/cgu.module').then((m) => m.CguModule),
  },
  {
    path: 'first-connexion',
    children: [
      {
        path: ':token',
        children: [
          {
            path: 'verify-identity-form',
            children: [
              {
                path: 'patient',
                loadChildren: () =>
                  import('./_pages/first-connexion/verify-identity-form/verify-identity-form.module')
                    .then((m) => m.VerifyIdentityFormModule),
                data: {userDiscr: 'Patient'},
              },
              {
                path: 'contact',
                loadChildren: () =>
                  import('./_pages/first-connexion/verify-identity-form/verify-identity-form.module')
                    .then((m) => m.VerifyIdentityFormModule),
                data: {userDiscr: 'Contact'},
              },
            ],
          },
          {
            path: 'verify-infos-form',
            loadChildren: () =>
              import('./_pages/first-connexion/verify-infos-form/verify-infos-form.module')
                .then((m) => m.VerifyInfosFormModule),
          },
          {
            path: 'verify-contact-form',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./_pages/first-connexion/verify-contact-form/verify-contact-form.module')
                    .then((m) => m.VerifyContactFormModule),
              },
              {
                path: 'success',
                loadChildren: () =>
                  import('./_pages/first-connexion/success-create-account/success-create-account.module')
                    .then((m) => m.SuccessCreateAccountModule),
              },
            ],
          },
        ],
      },
    ],
    canActivate: [() => inject(FollowMeAuthGuardsService).guardNoAuth()],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
