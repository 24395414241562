import {Inject, Injectable} from '@angular/core';
import {ApiClient, ENVIRONMENT} from '@forlabs/api-bridge';
import {FollowmeDynamicEnvironment} from '../environment/followme-dynamic-environment';

@Injectable({
  providedIn: 'root',
})
export class SpecificTranslationService {
  private organization: string | null;

  constructor(@Inject(ENVIRONMENT) private env: FollowmeDynamicEnvironment) {
    this.organization = env.organization ?? null;
  }

  public translate(trans: any, key: string, params: any): string {
    const fullKey = `${key}[${this.organization}]`;

    const translation =  trans(fullKey, params) as string;

    if (translation.includes(fullKey)) {
      return trans(`${key}`);
    }

    return translation;
  }
}
