import {Injectable} from '@angular/core';
import {relatedEntitySelectorWithTransformer} from '@followme/ngrx-entity-relationship';
import {buildOne} from '@forlabs/api-bridge';
import {Store} from '@ngrx/store';
import {Article} from '../articles/articles.models';
import {ArticleCollectionService} from '../articles/articles.ngrx-data';
import {Info} from '../infos/infos.models';
import {InfoCollectionService} from '../infos/infos.ngrx-data';
import {EntitySelectorService} from '../metadata';
import {Organization, OrganizationInterface} from './organizations.models';
import {OrganizationCollectionService} from './organizations.ngrx-data';


@Injectable()
export class OrganizationSelectorService extends EntitySelectorService<OrganizationInterface, Organization> {
  constructor(
    store: Store,
    organizationCollectionService: OrganizationCollectionService,
    articleCollectionService: ArticleCollectionService,
    infoCollectionService: InfoCollectionService,
  ) {
    const relOrganizationArticle = relatedEntitySelectorWithTransformer(
      articleCollectionService,
      'articlesIris' satisfies (keyof OrganizationInterface),
      'articles' satisfies (keyof OrganizationInterface),
      article => buildOne(Article, article),
    );

    const relOrganizationInfo = relatedEntitySelectorWithTransformer(
      infoCollectionService,
      'infosIris' satisfies (keyof OrganizationInterface),
      'infos' satisfies (keyof OrganizationInterface),
      info => buildOne(Info, info),
    );

    super(
      store,
      Organization,
      organizationCollectionService,
      [
        relOrganizationArticle(),
        relOrganizationInfo(),
      ],
    );
  }
}
