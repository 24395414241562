import {Injectable} from '@angular/core';
import {EntityActionFactory, EntityOp} from '@ngrx/data';
import {Store} from '@ngrx/store';
import {CourseMessage} from '../+state/course-messages/course-messages.models';
import {Info} from '../+state/infos/infos.models';
import {PatientStep} from '../+state/patient-steps/patient-steps.models';
import {Contact, Patient} from '../+state/users/users.models';


@Injectable({providedIn: 'root'})
export class MercureFallbackService {
  private enabled = false;
  private intervalId: number;

  public enable(): void {
    if (!this.enabled) {
      console.log('Enabling fallback mode');
      this.intervalId = setInterval(() => {
        // Reload patientSteps
        this.store.dispatch(
          this.actionFactory.create({
            entityName: PatientStep.getEntityName(),
            entityOp: EntityOp.QUERY_ALL,
          }),
        );

        // Reload course messages
        this.store.dispatch(
          this.actionFactory.create({
            entityName: CourseMessage.getEntityName(),
            entityOp: EntityOp.QUERY_ALL,
          }),
        );

        // Reload contacts
        this.store.dispatch(
          this.actionFactory.create({
            entityName: Contact.getEntityName(),
            entityOp: EntityOp.QUERY_ALL,
          }),
        );

        // Reload patients
        this.store.dispatch(
          this.actionFactory.create({
            entityName: Patient.getEntityName(),
            entityOp: EntityOp.QUERY_ALL,
          }),
        );

        // Reload infos
        this.store.dispatch(
          this.actionFactory.create({
            entityName: Info.getEntityName(),
            entityOp: EntityOp.QUERY_ALL,
          }),
        );
      }, 30 * 1000);
      this.enabled = true;
    }
  }

  public disable(): void {
    if (this.enabled) {
      console.log('Disabling fallback mode');
      clearInterval(this.intervalId);
      this.enabled = false;
    }
  }

  public constructor(
    private readonly store: Store,
    private readonly actionFactory: EntityActionFactory,
  ) {
  }
}
