import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';
import {DefaultDataServiceConfig, EntityCollectionServiceElementsFactory, HttpUrlGenerator} from '@ngrx/data';
import {Action, ScannedActionsSubject} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EntityCollectionService, FollowMeDataService} from '../metadata';
import {Organization, OrganizationInterface} from './organizations.models';


@Injectable()
export class OrganizationCollectionService extends EntityCollectionService<OrganizationInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
  ) {
    super(Organization, serviceElementsFactory, scannedActions$);
  }
}

@Injectable()
export class OrganizationDataService extends FollowMeDataService<OrganizationInterface> {
  constructor(
    apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(Organization, apiClient, http, httpUrlGenerator, config);
  }
}

// import {Injectable} from '@angular/core';
// import {createReducer} from '@ngrx/store';
// import {
//   AbstractEffects,
//   ActionsCreator,
//   AdapterCreator,
//   EntityStateWithLoading,
//   reducersForLoadAll,
//   SelectorsCreator
// } from '../definitions';
// import {Organization, OrganizationInterface} from './organizations.models';
//
// // STATE
// export type OrganizationsState = EntityStateWithLoading<OrganizationInterface>;
//
// // ADAPTER
// const adapterCreator = new AdapterCreator<OrganizationInterface>();
// export const organizationsAdapter = {
//   ...adapterCreator.getBaseAdapter(),
//   ...adapterCreator.getAdapterWithLoading<OrganizationsState>(),
//   // Add other stuff if necessary
// };
//
// // ACTIONS
// const actionsCreator = new ActionsCreator<OrganizationInterface, typeof Organization>(Organization);
// export const OrganizationsActions = {
//   ...actionsCreator.createLoadAll(),
// }
//
// // REDUCER
// export const organizationsReducer = createReducer(
//   organizationsAdapter.getInitialState(),
//   ...reducersForLoadAll(organizationsAdapter, OrganizationsActions),
//   // Add other stuff if necessary
// );
//
// // SELECTORS
// const selectorsCreator = new SelectorsCreator<OrganizationInterface, typeof Organization, OrganizationsState>(Organization, organizationsAdapter);
// export const OrganizationSelectors = {
//   ...selectorsCreator.createBase(),
//   // Add other stuff if necessary
// };
//
// // EFFECTS
// @Injectable()
// export class OrganizationsEffects extends AbstractEffects<OrganizationInterface, typeof Organization, OrganizationsState> {
//   protected entityConstructor = Organization;
//   protected selectors = OrganizationSelectors;
//
//   public loadAll$ = this.createLoadAll$(OrganizationsActions);
// }
