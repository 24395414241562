import {Action, ActionReducer, createReducer, MetaReducer, on} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {addIdInformation} from './feed.actions';


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['feed'],
    rehydrate: true,
  })(reducer);
}

export const feedMetaReducers: MetaReducer<{feed: FeedState; }, Action> = localStorageSyncReducer;

export interface FeedState {
  idInfos: string[];
}

export const initialFeedStates: FeedState = {
  idInfos: [],
};

export const feedReducer = createReducer(
  initialFeedStates,

  on(addIdInformation, (state, {id}) => {
    return {
      ...state,
      idInfos: [...state.idInfos, id],
    };
  }),
);