import {Injectable} from '@angular/core';
import {relatedEntitySelectorWithTransformer} from '@followme/ngrx-entity-relationship';
import {buildOne} from '@forlabs/api-bridge';
import {Store} from '@ngrx/store';
import {EntitySelectorService} from '../metadata';
import {PatientStep, PatientStepInterface} from '../patient-steps/patient-steps.models';
import {PatientStepCollectionService} from '../patient-steps/patient-steps.ngrx-data';
import {Patient} from '../users/users.models';
import {PatientCollectionService} from '../users/users.ngrx-data';
import {CourseMessage, CourseMessageInterface} from './course-messages.models';
import {CourseMessageCollectionService} from './course-messages.ngrx-data';


@Injectable()
export class CourseMessageSelectorService extends EntitySelectorService<CourseMessageInterface, CourseMessage> {
  constructor(
    store: Store,
    courseMessageCollectionService: CourseMessageCollectionService,
    patientCollectionService: PatientCollectionService,
    patientStepCollectionService: PatientStepCollectionService,
  ) {
    const relCourseMessagePatient = relatedEntitySelectorWithTransformer(
      patientCollectionService,
      'patientIri' satisfies (keyof CourseMessageInterface),
      'patient' satisfies (keyof CourseMessageInterface),
      patient => buildOne<Patient>(Patient, patient),
    );
    const relCourseMessagePatientStep = relatedEntitySelectorWithTransformer(
      patientStepCollectionService,
      'patientStepIri' satisfies (keyof CourseMessageInterface),
      'patientStep' satisfies (keyof CourseMessageInterface),
      patientStep => buildOne<PatientStepInterface>(PatientStep, patientStep),
    );

    super(
      store,
      CourseMessage,
      courseMessageCollectionService,
      [
        relCourseMessagePatient(),
        relCourseMessagePatientStep(),
      ],
    );
  }
}
