import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MERCURE_FEATURE_KEY, State} from './reducer';

// Lookup the 'Mercure' feature state managed by NgRx
export const getMercureState = createFeatureSelector<State>(MERCURE_FEATURE_KEY);

export const getStatus = createSelector(
  getMercureState,
  (state: State) => state.status,
);
