import {Injectable} from '@angular/core';
import {EntityCollection} from '@ngrx/data';
import {createSelector, MemoizedSelector, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EntitySelectorService} from '../metadata';
import {Article, ArticleInterface} from './articles.models';
import {ArticleCollectionService, ArticleMetadata} from './articles.ngrx-data';


@Injectable()
export class ArticleSelectorService<M = ArticleMetadata> extends EntitySelectorService<ArticleInterface, Article> {
  constructor(
    store: Store,
    private articleCollectionService: ArticleCollectionService,
  ) {
    super(store, Article, articleCollectionService);
  }

  public selectMetadata(): MemoizedSelector<unknown, M> {
    return createSelector(
      this.articleCollectionService.selectors.selectCollection,
      (collection: EntityCollection<ArticleInterface> & {metadata: M}) => collection.metadata,
    );
  }

  public selectMetadata$(): Observable<M> {
    return this.store.select(this.selectMetadata());
  }
}
