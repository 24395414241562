import {
  SuccessMessages,
  createSuccessMessages,
} from '@forlabs/api-bridge';


export const SUCCESS_MESSAGES: SuccessMessages = {
  article: createSuccessMessages('article', 'm-vowel'),
  info: createSuccessMessages('information', 'f-vowel'),
  organization: createSuccessMessages('organisation', 'f-vowel'),
  patient_step: createSuccessMessages('examen', 'm-vowel'),
  step: createSuccessMessages('étape', 'f-vowel'),
  contact: createSuccessMessages('contact', 'm'),
  health_pro: createSuccessMessages('professionnel', 'm'),
  admin: createSuccessMessages('admin', 'm-vowel'),
  patient: createSuccessMessages('patient', 'm'),
};
