import {createAction, props} from '@ngrx/store';
import {ArticleInterface} from '../articles/articles.models';
import {CourseMessageInterface} from '../course-messages/course-messages.models';
import {InfoInterface} from '../infos/infos.models';
import {PatientStepInterface} from '../patient-steps/patient-steps.models';
import {ContactInterface, PatientInterface} from '../users/users.models';


export class MercureActionTypes {
  public static SET_JWT = '[Mercure] Set JWT';
  public static SET_URL = '[Mercure] Set URL';
  public static CONNECT_SUCCESS = '[Mercure] Connect success';
  public static CONNECT_FAILURE = '[Mercure] Connect failure';
  public static CONNECT = '[Mercure] connect';
  public static SCHEDULE_RECONNECT = '[Mercure] reconnect';
  public static PROCESS_EVENT = '[Mercure] Process event';
  public static DISCONNECT = '[Mercure] disconnect';
  public static RELOAD_STEPS = '[Mercure] reload steps';
}

type CourseMessageEvent = CourseMessageInterface;
type PatientStepEvent = PatientStepInterface;
type InfoEvent = InfoInterface;
type PatientEvent = PatientInterface;
type ContactEvent = ContactInterface;

export type MercureEvent = CourseMessageEvent|PatientStepEvent|InfoEvent|PatientEvent|ContactEvent;

export const isCourseMessage = (event: object): event is CourseMessageInterface => event['@type'] === 'CourseMessage';
export const isPatientStep = (event: object): event is PatientStepInterface => event['@type'] === 'PatientStep';
export const isInfo = (event: object): event is InfoInterface => event['@type'] === 'Info';
export const isPatient = (event: object): event is PatientInterface => event['@type'] === 'Patient';
export const isContact = (event: object): event is ContactInterface => event['@type'] === 'Contact';
export const isArticle = (event: object): event is ArticleInterface => event['@type'] === 'Article';


export const mercureActionSetJwt = createAction(MercureActionTypes.SET_JWT, props<{ jwt: string }>());
export const mercureActionConnectSuccess = createAction(MercureActionTypes.CONNECT_SUCCESS);
export const mercureActionConnectFailure = createAction(MercureActionTypes.CONNECT_FAILURE);
// export const mercureActionProcessEvent = createAction(MercureActionTypes.PROCESS_EVENT, props<{ event: MercureEvent }>());
export const mercureActionProcessEvent = createAction(MercureActionTypes.PROCESS_EVENT, props<{ event: MercureEvent }>());
export const mercureActionDisconnect = createAction(MercureActionTypes.DISCONNECT);
export const mercureActionConnect = createAction(MercureActionTypes.CONNECT, props<{ jwt: string }>());
export const mercureActionScheduleReconnect = createAction(MercureActionTypes.SCHEDULE_RECONNECT, props<{ jwt: string }>());
