import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiClient, urlJoin} from '@forlabs/api-bridge';
import {
  CorrelationIdGenerator,
  DefaultDataService,
  DefaultDataServiceConfig,
  EntityCollectionServiceElementsFactory,
  EntityOp,
  HttpUrlGenerator,
  OP_ERROR,
  OP_SUCCESS,
  PersistanceCanceled,
} from '@ngrx/data';
import {EntityAction, EntityActionOptions} from '@ngrx/data/src/actions/entity-action';
import {HttpOptions} from '@ngrx/data/src/dataservices/interfaces';
import {Action, ScannedActionsSubject} from '@ngrx/store';
import {catchError, filter, map, mergeMap, Observable, of, shareReplay, switchMap, take, throwError} from 'rxjs';
import {TranslocoService} from '@jsverse/transloco';
import {EntityCollectionService} from '../metadata';
import {ForlabsEntityOp} from './articles.metadata';
import {Article, ArticleInterface} from './articles.models';


@Injectable()
export class ArticleCollectionService extends EntityCollectionService<ArticleInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
    private readonly correlationIdGenerator: CorrelationIdGenerator,
    private readonly articleDataService: ArticleDataService
  ) {
    super(Article, serviceElementsFactory, scannedActions$);
  }

  // Copied from parent.
  private setQueryEntityActionOptions(
    options?: EntityActionOptions,
  ): EntityActionOptions {
    options = options || {};
    const correlationId =
      options.correlationId == null
        ? this.correlationIdGenerator.next()
        : options.correlationId;
    return {...options, correlationId};
  }

  // Copied from parent.
  private getResponseData$<D = any>(crid: any): Observable<D> {
    /**
     * reducedActions$ must be replay observable of the most recent action reduced by the store.
     * because the response action might have been dispatched to the store
     * before caller had a chance to subscribe.
     */
    return this.reducedActions$.pipe(
      filter((act: any) => !!act.payload),
      filter((act: EntityAction) => {
        const {correlationId, entityName, entityOp} = act.payload;
        return (
          entityName === this.entityName &&
          correlationId === crid &&
          (entityOp.endsWith(OP_SUCCESS) ||
            entityOp.endsWith(OP_ERROR) ||
            entityOp === EntityOp.CANCEL_PERSIST)
        );
      }),
      take(1),
      mergeMap((act) => {
        const {entityOp} = act.payload;
        return entityOp === EntityOp.CANCEL_PERSIST
          ? throwError(new PersistanceCanceled(act.payload.data))
          : entityOp.endsWith(OP_SUCCESS)
            ? of(act.payload.data as D)
            : throwError(act.payload.data.error);
      }),
    );
  }

  public getMetadata(options?: EntityActionOptions): Observable<ArticleMetadata> {
    options = this.setQueryEntityActionOptions(options);
    const action = this.createEntityAction(
      ForlabsEntityOp.QUERY_METADATA as unknown as EntityOp,
      null,
      options,
    );
    this.dispatch(action);
    return this.getResponseData$<ArticleMetadata>(options.correlationId).pipe(
      shareReplay(1),
    );
  }
}

export type ArticleCategory = {
  id: string,
  name: string,
  description: string,
  icon: string,
  articleIds: string[],
}
export type ArticleMetadata = {
  articleIdsByStepName: {
    [stepName: string]: string,
  },
  overridesByCourseMessageId: {
    [courseMessageId: string]: string | null,
  },
  categories: ArticleCategory[],
};

@Injectable()
export class ArticleDataService extends DefaultDataService<ArticleInterface> {
  private isNewArchitecture?: boolean = null;
  constructor(
    protected apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private translocoService: TranslocoService,
    config?: DefaultDataServiceConfig,
  ) {
    super(Article.getEntityName(), http, httpUrlGenerator, {
      ...config,
      root: '/assets/articles',
    });

    this.entityUrl = '/assets/articles/';
    this.entitiesUrl = '/assets/articles/';
  }

  private checkFolderExists(url: string): Observable<boolean> {
    return this.http.get(url, {observe: 'response', responseType: 'json'}).pipe(
      switchMap(response => {
        if (response.status === 200 && this.isJson(response.body)) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return of(false);
      }),
    );
  }

  private isJson(value: any): boolean {
    try {
      JSON.stringify(value);
      return true;
    } catch {
      return false;
    }
  }

  public getMetadata(options?: HttpOptions): Observable<ArticleMetadata> {
    return this.ifNewArchitecture().pipe(
      switchMap(isNewArchitecture => {
        const url = isNewArchitecture ? urlJoin(this.entityUrl, this.translocoService.getActiveLang(), '_metadata.json')
          :urlJoin(this.entityUrl, '_metadata.json');

        return this.execute('GET', url, null, null, options);
      }),
    );
  }

  private ifNewArchitecture(): Observable<boolean> {
    const url = urlJoin(this.entityUrl,'fr', '_metadata.json');

    if (this.isNewArchitecture) {
      of(this.isNewArchitecture);
    }
    const result =  this.checkFolderExists(url);

    result.pipe(map(isNewArchitecture => this.isNewArchitecture = isNewArchitecture));

    return result;
  }

  public override getById(key: number | string, options?: HttpOptions): Observable<ArticleInterface> {
    const lang = this.translocoService.getActiveLang();

    return this.ifNewArchitecture().pipe(
      switchMap(isNewArchitecture => {
        const path = isNewArchitecture ? `${lang}/${key}.json` : `${key}.json`;

        return super.getById(path, options).pipe(map(article => ({
          ...article,
          '@id': key,
        }) as ArticleInterface));
      }),
    );
  }

  public override getAll(): never {
    throw new Error('Cannot get all articles');
  }

  public override add(): never {
    throw new Error('Cannot add articles');
  }

  public override delete(): never {
    throw new Error('Cannot delete articles');
  }

  public override update(): never {
    throw new Error('Cannot update articles');
  }
}
