import {Injectable} from '@angular/core';
import {Observable, map, tap} from 'rxjs';
import {ApiClient} from '@forlabs/api-bridge';
import {WorkflowButtonSelectorService} from '../+state/workflow-buttons/workflow-buttons.selectors';
import {Chart, ChartInterface} from '../ngrx/charts/charts.models';


@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor(
    public readonly workflowButtonSelectorService: WorkflowButtonSelectorService,
    private apiClient: ApiClient,
  ) {}

  public getCharts(): Observable<ChartInterface[]> {
    return this.apiClient.get<any>('/api/charts').pipe(
      map(response => {
        return response['hydra:member'];
      }),
    );
  }

  public createChart(chart: Chart): Observable<ChartInterface> {
    return this.apiClient.post<ChartInterface>('/api/charts', {
      'organization': chart.organization,
      'minValue': chart.minValue,
      'maxValue': chart.maxValue,
      'chartTitle': chart.chartTitle,
      'nbInterval': chart.nbInterval,
      'format': chart.format,
      'type': chart.type,
      'firstStep': chart.firstStep,
      'secondStep': chart.secondStep,
      'firstStatus': chart.firstStatus,
      'secondStatus': chart.secondStatus,
      'exitType1': chart.exitType1,
      'exitType2': chart.exitType2,
      'description': chart.description,
      'timeLimit': chart.timeLimit,
      'percent': chart.percent,
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }

  public updateChart(id: string, chart: ChartInterface): Observable<ChartInterface> {
    return this.apiClient.patch<ChartInterface>(`/api/charts/${id}`, {
      'minValue': chart.minValue,
      'maxValue': chart.maxValue,
      'chartTitle': chart.chartTitle,
      'nbInterval': chart.nbInterval,
      'format': chart.format,
      'type': chart.type,
      'firstStep': chart.firstStep,
      'secondStep': chart.secondStep,
      'firstStatus': chart.firstStatus,
      'secondStatus': chart.secondStatus,
      'exitType1': chart.exitType1,
      'exitType2': chart.exitType2,
      'description': chart.description,
      'timeLimit': chart.timeLimit,
      'percent': chart.percent,
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }

  public deleteChart(id: string): Observable<void> {
    return this.apiClient.delete(`/api/charts/${id}`).pipe(
      map(response => {
        return response;
      }),
    );
  }
}
