import {Injectable} from '@angular/core';
import {
  authActionLoginSuccess,
  authActionNotAuthenticated,
  AuthEffects,
  AuthEventInvalidCredentials,
  AuthService,
  AuthState,
  getAuthState,
  ToastService,
} from '@forlabs/api-bridge';
import {CorrelationIdGenerator} from '@ngrx/data';
import {Store} from '@ngrx/store';
import {distinctUntilChanged, Observable, shareReplay, tap} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {TranslocoService} from '@jsverse/transloco';
import {metaActionInit} from '../+state/_meta/actions';
import {Contact, HealthPro, Patient, UserInterface} from '../+state/users/users.models';


@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public readonly currentUser$ = this.authService.currentUser$ as Observable<HealthPro | Patient | Contact>;

  // Emits the current user's roles, or an empty array if no user is logged in
  public readonly currentUserRoles$: Observable<string[]> = this.currentUser$.pipe(
    map((user: UserInterface) => user.getRoles() ?? []),
    shareReplay(1),
  );

  private initialized = false;

  constructor(
    private store: Store,
    private authService: AuthService,
    private authEffects: AuthEffects,
    private toastService: ToastService,
    private correlationIdGenerator: CorrelationIdGenerator,
    private translocoService: TranslocoService,
  ) {}

  public isGranted$(role: string): Observable<boolean> {
    return this.currentUserRoles$.pipe(
      map((roles: string[]) => (roles ?? []).includes(role)),
    );
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.authEffects.events$.pipe(
      filter((event) => event instanceof AuthEventInvalidCredentials),
    ).subscribe(() => this.toastService.displayError(
      this.translocoService.translate('false_id'),
    ));

    this.store.select(getAuthState).pipe(
      take(1),
      tap((authState) => {
        if (authState.token && authState.id) {
          this.store.dispatch(authActionLoginSuccess({
            ...(authState as Required<AuthState>),
            correlationId: this.correlationIdGenerator.next(),
          }));
        } else {
          this.store.dispatch(authActionNotAuthenticated());
        }
      }),
    ).subscribe();

    this.currentUser$.pipe(
      filter(Boolean),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
      tap((user) => this.store.dispatch(metaActionInit({user}))),
    ).subscribe();

    this.initialized = true;
  }
}
