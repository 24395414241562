import {IonicColor} from '../../ui/ionic.definitions';
import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';


export type PlaceStatuses = {
  [name: string]: {
    status: string;
    color: IonicColor;
    backoffice_icon_priority: number;
  };
};
export type PlaceMetadata = {
  color?: IonicColor;
  implicit_steps?: PlaceStatuses;
}
export type PlacesMetadata = { [placeName: string]: PlaceMetadata }

export type TransitionMetadata = {
  delay?: number;
}
export type TransitionsMetadata = { [transitionName: string]: TransitionMetadata }

export type TransitionParameter = {
  name: string;
  type: 'string' | 'select'; // | 'number' | 'boolean' | 'date';
  options?: string[] | Readonly<string[]>;
  required?: boolean;
  // default_value?: string | number | boolean | Date;
}
export type WorkflowMetadata = {
  place: PlacesMetadata;
  transition: TransitionsMetadata;
  workflow: {
    transitions: {
      [transitionName: string]: {
        implicit_step?: string;
        parameters?: TransitionParameter[];
      }
    }
  };
}

export interface WorkflowInterface extends FollowMeEntityInterface {
  name: string;
  metadata: WorkflowMetadata;
}

export class Workflow extends AbstractFollowMeEntity implements WorkflowInterface {
  public override '@type' = 'Workflow' as const;

  public name: string = null;
  public metadata: WorkflowMetadata = null;

  public static override getEntityName(): 'workflow' {
    return 'workflow';
  }
}
