import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, of, tap} from 'rxjs';
import {ToastService} from '@forlabs/api-bridge';
import {TranslocoService} from '@jsverse/transloco';
import {ChartService} from '../../infrastructure/chart.service';
import {createChart, createChartSuccess, deleteChart, deleteChartFailure, deleteChartSuccess, getAll, loadChartsFailure, loadChartsSuccess, updateChart, updateChartFailure, updateChartSuccess} from './charts.actions';
import {Chart, ChartInterface} from './charts.models';


@Injectable()
export class ChartsEffects {
  constructor(
    private actions$: Actions,
    private chartService: ChartService,
    private toastService: ToastService,
    private translocoService: TranslocoService,
  ) {}

  public loadCharts$ = createEffect(() => this.actions$.pipe(
    ofType(getAll),
    exhaustMap(() => this.chartService.getCharts()
      .pipe(
        map(charts => {
          const areas_color = this.getAreasColor(charts);
          return loadChartsSuccess({charts, areas_color});
        }),
        catchError((error) => {
          console.error('Error loading charts:', error);
          return of(loadChartsFailure({error}));
        }),
      )),
  ));
  
  public createChart$ = createEffect(() => this.actions$.pipe(
    ofType(createChart),
    exhaustMap((action) => this.chartService.createChart(action.chart)
      .pipe(
        map((newChart: Chart) => {
          return createChartSuccess({chart: newChart});
        }),
        catchError((error) => {
          console.error('Error loading charts:', error);
          return of(updateChartFailure({error}));
        }),
      )),
  ));

  public updateChart$ = createEffect(() => this.actions$.pipe(
    ofType(updateChart),
    exhaustMap((action) => this.chartService.updateChart(action.id, action.chart)
      .pipe(
        map(updatedChart => {
          this.translocoService.selectTranslate(
            'pilotage.console-pilotage-graphique.chart-side-nav.chart-setup.page.update_success',
            {},
            {scope: 'backoffice'},
          ).subscribe(value => {
            this.toastService.presentToast(value);
          });
          return updateChartSuccess({id: action.id, chart: updatedChart});
        }),
        catchError((error) => {
          console.error('Error loading charts:', error);
          return of(updateChartFailure({error}));
        }),
      )),
  ));

  public deleteChart$ = createEffect(() => this.actions$.pipe(
    ofType(deleteChart),
    exhaustMap((action) => this.chartService.deleteChart(action.id)
      .pipe(
        map((rep) => {
          return deleteChartSuccess({id: action.id});
        }),
        catchError((error) => {
          return of(deleteChartFailure({error}));
        }),
      )),
  ));

  private getAreasColor(charts: ChartInterface[]): string[] {
    const areas_color = [];
    const resultByZone = charts.reduce((acc, chart) => {
      if (!acc['all']) {
        acc['all'] = [];
      }
      acc['all'].push(chart.status);
      chart.zones.forEach(zone => {
        if (!acc[zone.name]) {
          acc[zone.name] = [];
        }
        acc[zone.name].push(zone.status);
      });
      return acc;
    }, {} as { [key: string]: string[] });

    for (const zoneName in resultByZone) {
      const statuses = resultByZone[zoneName];
  
      if (statuses.includes('critical')) {
        areas_color[zoneName] = 'critical';
      } else if (statuses.includes('at_risk')) {
        areas_color[zoneName] = 'at_risk';
      } else {
        areas_color[zoneName] = 'stable';
      }
    }
    return areas_color;
  }
}