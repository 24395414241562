import {Directive, Input} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {IonicRouteStrategy, IonRouterOutlet} from '@ionic/angular';


export interface OnReInit {
  hasBeenNavigatedTo: boolean;
  onReInit(): void;
}

const implementsReInit = (componentInstance: unknown): componentInstance is OnReInit =>
  typeof (componentInstance as any)?.onReInit === 'function'
;

export class ReInitRouteReuseStrategy extends IonicRouteStrategy {
  public override shouldReuseRoute(_future: ActivatedRouteSnapshot, _curr: ActivatedRouteSnapshot): boolean {
    return false;
    // TODO: Don't reuse route if component implements OnReInit, but otherwise do re-use them if Ionic would.
    //  Problem 1: curr.component is not a component instance, but the class.
    //  Problem 2: curr.component is the top-level component. RouterState points to the tree of loaded components,
    //             but it doesn't look like there's a simple way to get the component loaded by *this* router outlet here.
    // return super.shouldReuseRoute(future, curr) && implementsReInit(curr.component);
  }
}


/**
 * Usage:
 *
 * Requires onSameUrlNavigation: 'reload' in root routing module:
 *   RouterModule.forRoot(routes, {
 *     preloadingStrategy: PreloadAllModules,
 *     onSameUrlNavigation: 'reload',
 *   }),
 *
 * Requires a specific RouteReusedStrategy provider in app module:
 *   {provide: RouteReuseStrategy, useClass: ReInitRouteReuseStrategy},
 */
@Directive({
  selector: 'ion-router-outlet[fmReInit]',
})
export class ReInitDirective {
  @Input()
  public kloReInit = false;

  // TODO: See https://github.com/angular/angular/issues/8277 to make this available to different types of components
  //  (like ion router outlet AND ng router outlet)
  constructor(private host: IonRouterOutlet) {
    host.activateEvents.subscribe((event) => this.onRouterOutletActivate(event));
  }

  public onRouterOutletActivate(componentInstance: unknown): void {
    if (implementsReInit(componentInstance)) {
      // On first load, do nothing special
      if (!componentInstance.hasBeenNavigatedTo) {
        componentInstance.hasBeenNavigatedTo = true;
        return;
      }

      // On subsequent loads, trigger onReInit
      componentInstance.onReInit();
    }
  }
}
