import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';
import {DefaultDataServiceConfig, EntityCollectionServiceElementsFactory, HttpUrlGenerator} from '@ngrx/data';
import {Action, ScannedActionsSubject} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EntityCollectionService, FollowMeDataService} from '../metadata';
import {Contact, ContactInterface, HealthPro, HealthProInterface, Patient, PatientInterface} from './users.models';


@Injectable()
export class HealthProCollectionService extends EntityCollectionService<HealthProInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
  ) {
    super(HealthPro, serviceElementsFactory, scannedActions$);
  }
}

@Injectable()
export class HealthProDataService extends FollowMeDataService<HealthProInterface> {
  constructor(
    apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(HealthPro, apiClient, http, httpUrlGenerator, config);
  }
}


@Injectable()
export class ContactCollectionService extends EntityCollectionService<ContactInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
  ) {
    super(Contact, serviceElementsFactory, scannedActions$);
  }
}

@Injectable()
export class ContactDataService extends FollowMeDataService<ContactInterface> {
  constructor(
    apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(Contact, apiClient, http, httpUrlGenerator, config);
  }
}


@Injectable()
export class PatientCollectionService extends EntityCollectionService<PatientInterface> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(ScannedActionsSubject) scannedActions$: Observable<Action>,
  ) {
    super(Patient, serviceElementsFactory, scannedActions$);
  }
}

@Injectable()
export class PatientDataService extends FollowMeDataService<PatientInterface> {
  constructor(
    apiClient: ApiClient,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(Patient, apiClient, http, httpUrlGenerator, config);
  }
}
