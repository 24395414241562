import {HttpErrorResponse} from '@angular/common/http';
import {Injector, NgModule} from '@angular/core';
import {
  authActionLogout,
  BaseErrorModule,
  ForlabsExceptionUUIDs, ForlabsHttpException,
  HTTP_ERROR_HANDLER,
  HttpErrorHandlerModule,
  isForlabsException, ToastService,
} from '@forlabs/api-bridge';
import {createAction, props, Store} from '@ngrx/store';
import {of} from 'rxjs';


// ========= If this works, put it in api-bridge =========
// No effect is attached to this action by default, but the app can choose to do so (to display a toast or something).
export const authActionUserNotFound = createAction('[Auth] User not found', props<{ error: ForlabsHttpException }>());
export const disabledUserErrorHandler = {
  matches: (response: HttpErrorResponse): boolean =>
    isForlabsException(response) && response.status === 401 && response.error.uuid === '281c3bfe-ace7-4d0e-a29f-ad329d125738',
  handle: (response: HttpErrorResponse, injector: Injector): null => {
    const store = injector.get(Store);
    store.dispatch(authActionUserNotFound({error: response}));
    store.dispatch(authActionLogout());
    return null;
  },
  priority: 500, // Handlers that target a very specific error can have high priority
};
// ========= If this works, put it in api-bridge =========

const invalidCredentialsHandler = {
  matches: (r: HttpErrorResponse) => isForlabsException(r) && r.error.uuid === ForlabsExceptionUUIDs.InvalidCredentials,
  handle: (r: HttpErrorResponse, injector: Injector) => {
    console.log('handle', r);
    injector.get(ToastService).displayError(r.error);
    return of(null);
  },
  priority: 1500, // Handlers that target a very specific error can have high priority
};


@NgModule({
  imports: [
    BaseErrorModule,
    HttpErrorHandlerModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_ERROR_HANDLER, useValue: invalidCredentialsHandler, multi: true},
    {provide: HTTP_ERROR_HANDLER, useValue: disabledUserErrorHandler, multi: true},
    // {provide: ERROR_NORMALIZER, useValue: ..., multi: true},
  ],
})
export class ErrorModule {
}
