import {Injectable} from '@angular/core';
import {relatedEntitySelectorWithTransformer} from '@followme/ngrx-entity-relationship';
import {buildOne} from '@forlabs/api-bridge';
import {Store} from '@ngrx/store';
import {EntitySelectorService} from '../metadata';
import {Patient} from '../users/users.models';
import {PatientCollectionService} from '../users/users.ngrx-data';
import {Workflow} from '../workflows/workflows.models';
import {WorkflowCollectionService} from '../workflows/workflows.ngrx-data';
import {PatientStep, PatientStepInterface} from './patient-steps.models';
import {PatientStepCollectionService} from './patient-steps.ngrx-data';


@Injectable()
export class PatientStepSelectorService extends EntitySelectorService<PatientStepInterface, PatientStep> {
  constructor(
    store: Store,
    patientStepCollectionService: PatientStepCollectionService,
    patientCollectionService: PatientCollectionService,
    workflowCollectionService: WorkflowCollectionService,
  ) {
    const relPatientStepPatient = relatedEntitySelectorWithTransformer(
      patientCollectionService,
      'patientIri' satisfies (keyof PatientStepInterface),
      'patient' satisfies (keyof PatientStepInterface),
      patient => buildOne<Patient>(Patient, patient),
    );
    const relPatientStepWorkflow = relatedEntitySelectorWithTransformer(
      workflowCollectionService,
      'step' satisfies (keyof PatientStepInterface),
      'workflow' satisfies (keyof PatientStepInterface),
      workflow => buildOne<Workflow>(Workflow, workflow),
    );

    super(
      store,
      PatientStep,
      patientStepCollectionService,
      [
        relPatientStepPatient(),
        relPatientStepWorkflow(),
      ],
    );
  }
}
