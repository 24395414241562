import {relatedEntityWithTransformer} from './relatedEntityWithTransformer';
import {FEATURE_SELECTOR, HANDLER_RELATED_ENTITY, ID_TYPES, isSelectorMeta, TRANSFORMER} from './types';
import {argsToArray} from './utils';


export function relatedEntitySelectorWithTransformer<
  STORE,
  PARENT_ENTITY extends
  | {
    [KEY in RELATED_KEY_IDS | RELATED_KEY_VALUES]?: KEY extends RELATED_KEY_IDS
      ? ID_TYPES
      : KEY extends RELATED_KEY_VALUES
        ? TRANSFORMED_RELATED_ENTITY | null
        : never;
  }
  | {
    [KEY in RELATED_KEY_IDS | RELATED_KEY_VALUES]?: KEY extends RELATED_KEY_IDS
      ? Array<ID_TYPES>
      : KEY extends RELATED_KEY_VALUES
        ? Array<TRANSFORMED_RELATED_ENTITY> | null
        : never;
  },
  RELATED_ENTITY,
  TRANSFORMED_RELATED_ENTITY,
  RELATED_KEY_IDS extends keyof any = keyof any,
  RELATED_KEY_VALUES extends keyof any = keyof any,
>(
  featureSelector: FEATURE_SELECTOR<STORE, RELATED_ENTITY>,
  keyId: RELATED_KEY_IDS,
  keyValue: RELATED_KEY_VALUES,
  transformer: TRANSFORMER<RELATED_ENTITY, TRANSFORMED_RELATED_ENTITY>,
  meta?: SELECTOR_META,
): (
  metaOrRelationship?: SELECTOR_META | HANDLER_RELATED_ENTITY<STORE, RELATED_ENTITY>,
  ...relationships: Array<HANDLER_RELATED_ENTITY<STORE, RELATED_ENTITY>>
) => HANDLER_RELATED_ENTITY<STORE, PARENT_ENTITY>;

export function relatedEntitySelectorWithTransformer<
  STORE,
  PARENT_ENTITY extends
  | {
    [KEY in RELATED_KEY_IDS | RELATED_KEY_VALUES]?: KEY extends RELATED_KEY_IDS
      ? ID_TYPES
      : KEY extends RELATED_KEY_VALUES
        ? TRANSFORMED_RELATED_ENTITY | null
        : never;
  }
  | {
    [KEY in RELATED_KEY_IDS | RELATED_KEY_VALUES]?: KEY extends RELATED_KEY_IDS
      ? Array<ID_TYPES>
      : KEY extends RELATED_KEY_VALUES
        ? Array<TRANSFORMED_RELATED_ENTITY> | null
        : never;
  },
  RELATED_ENTITY,
  TRANSFORMED_RELATED_ENTITY,
  RELATED_KEY_IDS extends keyof any,
  RELATED_KEY_VALUES extends keyof any,
>(
  featureSelector: FEATURE_SELECTOR<STORE, RELATED_ENTITY>,
  keyId: RELATED_KEY_IDS,
  keyValue: RELATED_KEY_VALUES,
  transformer: TRANSFORMER<RELATED_ENTITY, TRANSFORMED_RELATED_ENTITY>,
  meta?: SELECTOR_META,
): (
    metaOrRelationship?: SELECTOR_META | HANDLER_RELATED_ENTITY<STORE, RELATED_ENTITY>,
    ...relationships: Array<HANDLER_RELATED_ENTITY<STORE, RELATED_ENTITY>>
  ) => HANDLER_RELATED_ENTITY<STORE, PARENT_ENTITY> {
  function callback(): HANDLER_RELATED_ENTITY<STORE, PARENT_ENTITY> {
    let relationships: Array<HANDLER_RELATED_ENTITY<STORE, RELATED_ENTITY>> = argsToArray(arguments);
    let currentMeta = meta;
    if (isSelectorMeta(relationships[0])) {
      currentMeta = relationships[0];
      relationships = relationships.slice(1);
    }
    return currentMeta
      ? relatedEntityWithTransformer<STORE, PARENT_ENTITY, RELATED_ENTITY, TRANSFORMED_RELATED_ENTITY, any, any, any, any>(
        featureSelector,
        keyId,
        keyValue,
        transformer,
        currentMeta,
        ...relationships,
      )
      : relatedEntityWithTransformer<STORE, PARENT_ENTITY, RELATED_ENTITY, TRANSFORMED_RELATED_ENTITY, any, any, any, any>(
        featureSelector,
        keyId,
        keyValue,
        transformer,
        ...relationships,
      );
  }
  callback.ngrxEntityRelationship = 'relatedEntitySelector';

  return callback;
}
